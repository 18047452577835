import React, { useEffect, useState } from "react";
import { validation } from "../../helpers";
import {
  Text,
  Button,
  CheckBox,
  Input,
  Heading,
  Img,
  SelectBox,
} from "../../components";
import { Link } from "react-router-dom";
import { register } from "../../services/auth";
import { useNavigate } from "react-router";
import DefaultModal from "../../components/DefaultModal";
import * as loader from "../../components/Loading/loading";

const categoryOptions = [{ value: "ORICAT-AR-RAHNU", label: "Ar_Rahnu" }];

export default function RegisterPage() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const [category, setCategory] = useState(null);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [rePassError, setRePassError] = useState("");
  const [roleError, setRoleError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [registerError, setRegisterError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const isFormValid =
    !nameError &&
    !emailError &&
    !passwordError &&
    !rePassError &&
    !phoneError &&
    !roleError &&
    !categoryError &&
    name &&
    email &&
    password &&
    rePassword &&
    phone &&
    role &&
    category &&
    checkbox;

  useEffect(() => {
    if (categoryOptions.length > 0) {
      setCategory(categoryOptions[0]);
    }
  }, []);

  const handleRegister = async () => {
    loader.showLoader();
    try {
      const isSuccess = await register(
        name,
        email,
        password,
        phone,
        role?.value,
        category?.value
      );
      if (isSuccess) {
        setIsOpen(true);
      }
    } catch (error) {
      setRegisterError(error);
    } finally {
      loader.hideLoader();
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    navigate("/login");
  };

  return (
    <div className="bg-white flex justify-center items-center lg:h-screen w-screen">
      <div className="grid grid-cols-1 lg:grid-cols-2 justify-between items-center w-full lg:max-w-[1241px]">
        <Img src="images/logo.png" alt="logo" className="" />
        <div className="flex flex-col items-center justify-start px-10 lg:px-20">
          <div className="flex flex-row justify-center w-full">
            <div className="flex flex-row justify-center w-full">
              <Heading
                size="9xl"
                as="h2"
                className="!text-blue_gray-900_03 text-center"
              >
                Get Start Now
              </Heading>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full gap-[23px] my-8">
            <div className="flex flex-col items-center justify-start w-full gap-5">
              <div className="flex flex-col items-start justify-start w-full gap-[7px]">
                <Text as="p" className="!text-blue_gray-700">
                  Name
                </Text>
                <Input
                  type="text"
                  name="name"
                  onChange={(value) => {
                    setName(value);
                    setNameError(validation.required(value));
                  }}
                  placeholder="Enter your name"
                  className="w-full border-blue_gray-100_02 border border-solid"
                />
                {nameError && (
                  <Text as="p" className="text-red-500">
                    {nameError}
                  </Text>
                )}
              </div>
              <div className="flex flex-col items-start justify-start w-full gap-[7px]">
                <Text as="p" className="!text-blue_gray-700">
                  Email
                </Text>
                <Input
                  type="email"
                  name="email"
                  onChange={(e) => {
                    setEmail(e);
                    setEmailError(validation.email(e));
                  }}
                  placeholder="Enter your email"
                  className="w-full border-blue_gray-100_02 border border-solid"
                />
                {emailError && (
                  <Text as="p" className="text-red-500">
                    {emailError}
                  </Text>
                )}
              </div>
              <div className="flex flex-col items-start justify-start w-full gap-[7px]">
                <Text as="p" className="!text-blue_gray-700">
                  Phone
                </Text>
                <Input
                  type="tel"
                  name="phone"
                  placeholder="Enter your phone"
                  onChange={(e) => {
                    setPhone(e);
                    setPhoneError(validation.phoneNumber(e));
                  }}
                  className="w-full border-blue_gray-100_02 border border-solid"
                />
                {phoneError && (
                  <Text as="p" className="text-red-500">
                    {phoneError}
                  </Text>
                )}
              </div>
              <div className="flex flex-col items-start justify-start w-full gap-[7px]">
                <Text as="p" className="!text-blue_gray-700">
                  Role
                </Text>
                <SelectBox
                  options={[
                    { value: "originator", label: "Originator" },
                    { value: "fund_manager", label: "Fund Manager" },
                  ]}
                  onChange={(selectedOption) => {
                    setRole(selectedOption);
                    setRoleError(validation.required(selectedOption));
                  }}
                  placeholder="Select your role"
                  className="w-full border-blue_gray-100_02 border border-solid"
                />
                {roleError && (
                  <Text as="p" className="text-red-500">
                    {roleError}
                  </Text>
                )}
              </div>

              {role?.value === "originator" && (
                <div className="flex flex-col items-start justify-start w-full gap-[7px]">
                  <Text as="p" className="!text-blue_gray-700">
                    Category
                  </Text>
                  <SelectBox
                    options={categoryOptions}
                    onChange={(selectedOption) => {
                      setCategory(selectedOption);
                      setCategoryError(validation.required(selectedOption));
                    }}
                    value={
                      categoryOptions?.find(
                        (item) => item.value === category?.value
                      ) || null
                    }
                    placeholder="Select your Category"
                    className="w-full border-blue_gray-100_02 border border-solid"
                    isDisabled={true}
                  />
                  {categoryError && (
                    <Text as="p" className="text-red-500">
                      {categoryError}
                    </Text>
                  )}
                </div>
              )}
              <div className="flex flex-col items-start justify-start w-full gap-[7px]">
                <Text as="p" className="!text-blue_gray-700">
                  Password
                </Text>
                <Input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="••••••••"
                  onChange={(e) => {
                    setPassword(e);
                    setPasswordError(validation.passwordLength(e));
                  }}
                  suffix={
                    showPassword ? (
                      <Img
                        src="images/img_eyes_close.svg"
                        onClick={() => setShowPassword(false)}
                        className="cursor-pointer w-4 h-4"
                      />
                    ) : (
                      <Img
                        src="images/img_eyes.svg"
                        onClick={() => setShowPassword(true)}
                        className="cursor-pointer w-4 h-4"
                      />
                    )
                  }
                  className="w-full border-blue_gray-100_02 border border-solid"
                />
                {passwordError && (
                  <Text as="p" className="text-red-500">
                    {passwordError}
                  </Text>
                )}
              </div>
              <div className="flex flex-col items-start justify-start w-full pt-0.5 gap-1.5">
                <Text as="p" className="!text-blue_gray-700">
                  Repeat Password
                </Text>
                <Input
                  type={showPasswordConfirm ? "text" : "password"}
                  name="password"
                  placeholder="••••••••"
                  onChange={(e) => {
                    setRePassword(e);
                    setRePassError(validation.passwordMatch(password, e));
                  }}
                  suffix={
                    showPasswordConfirm ? (
                      <Img
                        src="images/img_eyes_close.svg"
                        onClick={() => setShowPasswordConfirm(false)}
                        className="cursor-pointer w-4 h-4"
                      />
                    ) : (
                      <Img
                        src="images/img_eyes.svg"
                        onClick={() => setShowPasswordConfirm(true)}
                        className="cursor-pointer w-4 h-4"
                      />
                    )
                  }
                  className="w-full border-blue_gray-100_02 border border-solid"
                />
                {rePassError && (
                  <Text as="p" className="text-red-500">
                    {rePassError}
                  </Text>
                )}
              </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-start lg:items-center gap-1.5">
              <CheckBox
                onChange={(value) => setCheckbox(value)}
                name="readAgree"
                label="I have read and agree to the"
                className="!text-blue_gray-700 !font-poppins gap-2 text-left font-medium"
              />
              <Link
                to="/terms-conditions"
                className="text-indigo-900  font-inter underline font-medium"
              >
                Terms & Conditions
              </Link>
            </div>
            {registerError && (
              <Text as="p" className="text-red-500">
                {registerError}
              </Text>
            )}
            <Button
              onClick={handleRegister}
              disable={!isFormValid}
              size="2xl"
              className="w-full ml-px font-medium !shadow-sm"
            >
              Sign Up
            </Button>
            <Link to="/login">
              <Text as="p" className="!text-blue_gray-700">
                <span className="text-blue_gray-700">I have an account, </span>
                <span className="text-indigo-900 ">Login</span>
              </Text>
            </Link>
          </div>
        </div>
      </div>
      <DefaultModal
        isOpen={isOpen}
        title="Attention"
        desc="Please verify your account through the activation link sent to your email."
        onButtonClick={handleCloseModal}
      />
    </div>
  );
}
