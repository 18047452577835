import React, { useEffect, useRef } from "react";
import { Text, Heading, Img } from "../../../components"; // Pastikan path sesuai struktur Anda
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import Header from "components/Header";

// Dummy Data
const dummyData = {
  pendingPool: 80,
  activePool: 120,
};

export default function PoolDashboard() {
  const chartRef = useRef(null);

  useEffect(() => {
    // Inisialisasi amCharts
    let root = am5.Root.new(chartRef.current);
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
      })
    );

    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
      })
    );

    // Masukkan data dummy ke chart
    series.data.setAll([
      { category: "Pending Pool", value: dummyData.pendingPool },
      { category: "Active Pool", value: dummyData.activePool },
    ]);

    // Animasi awal
    series.appear(1000, 100);

    // Cleanup
    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-start w-full pb-[84px] gap-[34px] bg-gray-50">
      <Header className="w-full z-10" />
      {/* Container Cards & Chart */}
      {dummyData ? (
        <div className="flex flex-col items-center justify-center md:justify-start w-full gap-[31px] max-w-[1241px] mt-14 md:mt-20 px-5">
          <div className="w-full relative rounded-xl lg:rounded-[30px] bg-white shadow-2xl p-5 flex flex-col gap-8">
            {/* Bagian Cards (Pending Pool & Active Pool) */}
            <div className="lg:h-[175px] grid grid-cols-1 md:grid-cols-2 w-full relative gap-3">
              {/* Card - Pending Pool */}
              <div className="flex flex-col md:flex-row items-center gap-2 lg:gap-5 rounded-xl lg:rounded-[30px] p-3 lg:p-[33px] bg-white">
                <div className="flex items-center justify-center h-12 w-12 lg:w-[98px] lg:h-[98px] bg-gradient1 rounded-full relative">
                  <Img
                    src="images/img_vuesax_linear_profile_2user.svg"
                    alt="Icon Pending"
                    className="h-6 lg:h-[49px]"
                  />
                </div>
                <div className="flex flex-col text-center md:text-left gap-[5px]">
                  <Text
                    as="p"
                    className="md:!text-base !text-xs !text-gray-500_01 tracking-[-0.14px] !font-poppins !font-normal"
                  >
                    Pending Pool
                  </Text>
                  <Heading
                    size="10xl"
                    as="h1"
                    className="!text-blue_gray-900_02 tracking-[-0.32px]"
                  >
                    {dummyData.pendingPool}
                  </Heading>
                </div>
              </div>

              {/* Card - Active Pool */}
              <div className="flex flex-col md:flex-row items-center gap-2 lg:gap-5 rounded-xl lg:rounded-[30px] p-3 lg:p-[33px] bg-white">
                <div className="flex items-center justify-center h-12 w-12 lg:w-[98px] lg:h-[98px] bg-gradient1 rounded-full relative">
                  <Img
                    src="images/img_vuesax_linear_profile_2user.svg"
                    alt="Icon Active"
                    className="h-6 lg:h-[49px]"
                  />
                </div>
                <div className="flex flex-col text-center md:text-left gap-[5px]">
                  <Text
                    as="p"
                    className="md:!text-base !text-xs !text-gray-500_01 tracking-[-0.14px] !font-poppins !font-normal"
                  >
                    Active Pool
                  </Text>
                  <Heading
                    size="10xl"
                    as="h1"
                    className="!text-blue_gray-900_02 tracking-[-0.32px]"
                  >
                    {dummyData.activePool}
                  </Heading>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full relative rounded-xl lg:rounded-[30px] bg-white shadow-2xl p-5 flex flex-col gap-8">
            {/* Bagian Pie Chart */}
            <div className="w-full h-[400px] bg-white rounded-xl overflow-hidden">
              <div ref={chartRef} className="w-full h-full" />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full h-[90vh] justify-center items-center">
          Loading...
        </div>
      )}
    </div>
  );
}
