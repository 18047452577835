export function logout() {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("userID");
  localStorage.removeItem("username");
  localStorage.removeItem("kycStatus");
  localStorage.removeItem("userRole");
}

export function isLoggedIn() {
  return !!localStorage.getItem("accessToken");
}

export function token() {
  return localStorage.getItem("accessToken");
}

export function userID() {
  return localStorage.getItem("userID");
}
