import React from "react";
import { Img, Button } from "./..";
import { Link } from "react-router-dom";
import { isLoggedIn } from "constant/localStorage";
import { userRoleData } from "constant/userRole";

export default function MainHeader({ ...props }) {
  const kycStatus = localStorage.getItem("kycStatus");
  const userRole = localStorage.getItem("userRole");

  const checkRoleFm = () => {
    return userRole && userRole === userRoleData.FUND_MANAGER ? true : false;
  };

  const linkForm = () => {
    return checkRoleFm ? "/fund-manager-form" : "/originator-form";
  };

  const linkProfile = () => {
    return checkRoleFm ? "/fund-manager" : "/originator";
  };

  const linkDashboard = () => {
    return checkRoleFm ? "/fund-manager-dashboard" : "/dashboard";
  };

  return (
    <header
      {...props}
      className="flex flex-col items-center justify-start w-full fixed shadow-2xl z-10"
    >
      <div className="flex flex-row justify-end w-full bg-white shadow-xs">
        <div className="flex flex-row justify-between items-center w-full mx-auto max-w-[1241px] py-2 px-5">
          <Link to="/">
            <Img
              src="images/logo_crop.png"
              alt="logo"
              className="w-[120px] md:w-[150px]"
            />
          </Link>
          {isLoggedIn() ? (
            <Link
              to={
                kycStatus === "pending"
                  ? linkForm()
                  : kycStatus === "awaiting"
                  ? linkProfile()
                  : linkDashboard()
              }
            >
              <Button
                size="xl"
                variant="gradient"
                shape="square"
                color="yellow_A700_amber_500_01"
                className="font-poppins font-semibold px-2 lg:min-w-[170px] rounded-xl"
              >
                Go to Dashboard
              </Button>
            </Link>
          ) : (
            <Link to="/login">
              <Button
                size="xl"
                variant="gradient"
                shape="square"
                color="yellow_A700_amber_500_01"
                className="font-poppins font-semibold min-w-[100px] rounded-xl"
              >
                Login
              </Button>
            </Link>
          )}
        </div>
      </div>
    </header>
  );
}
