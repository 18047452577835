import React, { useState, useRef } from "react";
import {
  Button,
  Text,
  Input,
  Img,
  Heading,
  TextArea,
} from "../../../components";
import { useNavigate } from "react-router";
import { validation } from "../../../helpers"; // misal butuh validasi dari helper
import Header from "../../../components/Header";
import DefaultModal from "../../../components/DefaultModal";
import * as loader from "../../../components/Loading/loading";

export default function FundManagerForm() {
  const navigate = useNavigate();
  const drop = useRef(null);

  // State
  const [companyName, setCompanyName] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [personInCharge, setPersonInCharge] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);

  // Error
  const [errors, setErrors] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // Validasi
  const validateForm = () => {
    let newErrors = {};

    if (!companyName) {
      newErrors.companyName = "Company Name is required";
    }
    if (!companyPhone) {
      newErrors.companyPhone = "Company Phone Number is required";
    }
    if (!companyAddress) {
      newErrors.companyAddress = "Company Address is required";
    }
    if (!personInCharge) {
      newErrors.personInCharge = "Person in Charge is required";
    }
    if (!registrationNumber) {
      newErrors.registrationNumber = "Registration Number is required";
    }
    if (!selectedFile) {
      newErrors.selectedFile = "Company Profile is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Submit KYC
  const handleSubmitKyc = (e) => {
    e.preventDefault();
    if (validateForm()) {
      loader.showLoader();
      // simulasi async
      setTimeout(() => {
        loader.hideLoader();
        setIsOpen(true);
      }, 1000);
    }
  };

  // Upload File
  const handleUploadFile = (file) => {
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
    if (file && allowedTypes.includes(file.type)) {
      setSelectedFile(file);
      setSelectedFileName(file.name);
      setErrors((prev) => ({ ...prev, selectedFile: "" }));
    } else {
      setErrors((prev) => ({
        ...prev,
        selectedFile: "Invalid file type. Only PDF, JPG, PNG allowed.",
      }));
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full pb-10 lg:pb-20 gap-[73px] bg-gray-50 relative">
      {/* Header */}
      <Header className="w-full mb-5 lg:mb-20" />

      {/* Container Utama */}
      <div className="flex flex-col items-center justify-start w-full gap-10 px-5 lg:gap-16 lg:max-w-[1241px]">
        <Heading
          size="9xl"
          as="h1"
          className="!text-blue_gray-900_03 text-center"
        >
          Complete your Fund Manager account to continue using our product.
        </Heading>

        {/* Kotak Form */}
        <div className="flex flex-col items-center justify-center mb-[11px] gap-10 lg:w-[76%] px-8 md:px-[55px] py-10 md:py-[63px] border-blue_gray-100_01 border border-solid bg-white rounded-[10px]">
          {/* Form */}
          <form
            onSubmit={handleSubmitKyc}
            className="w-full flex flex-col gap-[19px]"
          >
            {/* Company Name */}
            <div className="flex flex-col gap-1.5">
              <Text as="p" className="!text-blue_gray-700">
                Company Name
              </Text>
              <Input
                type="text"
                value={companyName}
                onChange={(val) => {
                  setCompanyName(val);
                  // Bisa tambahkan validasi real-time di sini, misal: setErrors({ ...errors, companyName: validation.required(val) })
                }}
                placeholder="Enter your company name"
                className="w-full border-blue_gray-100_02 border border-solid"
              />
              {errors.companyName && (
                <Text as="p" className="text-red-500">
                  {errors.companyName}
                </Text>
              )}
            </div>

            {/* Company Phone */}
            <div className="flex flex-col gap-1.5">
              <Text as="p" className="!text-blue_gray-700">
                Company Phone Number
              </Text>
              <Input
                type="tel"
                value={companyPhone}
                onChange={(val) => {
                  setCompanyPhone(val);
                }}
                placeholder="Enter your company phone number"
                className="w-full border-blue_gray-100_02 border border-solid"
              />
              {errors.companyPhone && (
                <Text as="p" className="text-red-500">
                  {errors.companyPhone}
                </Text>
              )}
            </div>

            {/* Company Address */}
            <div className="flex flex-col gap-1.5">
              <Text as="p" className="!text-blue_gray-700">
                Company Address
              </Text>
              <TextArea
                value={companyAddress}
                onChange={(val) => {
                  setCompanyAddress(val);
                }}
                placeholder="Enter your company address"
                className="w-full border-blue_gray-100_02 border border-solid"
              />
              {errors.companyAddress && (
                <Text as="p" className="text-red-500">
                  {errors.companyAddress}
                </Text>
              )}
            </div>

            {/* Person in Charge */}
            <div className="flex flex-col gap-1.5">
              <Text as="p" className="!text-blue_gray-700">
                Person in Charge
              </Text>
              <Input
                type="text"
                value={personInCharge}
                onChange={(val) => setPersonInCharge(val)}
                placeholder="Enter name of person in charge"
                className="w-full border-blue_gray-100_02 border border-solid"
              />
              {errors.personInCharge && (
                <Text as="p" className="text-red-500">
                  {errors.personInCharge}
                </Text>
              )}
            </div>

            {/* Registration Number */}
            <div className="flex flex-col gap-1.5">
              <Text as="p" className="!text-blue_gray-700">
                Registration Number
              </Text>
              <Input
                type="text"
                value={registrationNumber}
                onChange={(val) => setRegistrationNumber(val)}
                placeholder="Enter your registration number"
                className="w-full border-blue_gray-100_02 border border-solid"
              />
              {errors.registrationNumber && (
                <Text as="p" className="text-red-500">
                  {errors.registrationNumber}
                </Text>
              )}
            </div>

            {/* Upload Company Profile */}
            <div className="flex flex-col gap-1.5">
              <Text as="p" className="!text-blue_gray-700">
                Upload Company Profile
              </Text>
              <div
                ref={drop}
                onClick={() => document.getElementById("fileInput").click()}
                className="flex flex-row justify-start items-center w-full gap-6 p-4 border-blue_gray-300_01 border border-dashed rounded-[10px] cursor-pointer"
              >
                <Img
                  src="images/img_feather_upload_cloud.svg"
                  alt="upload"
                  className="h-12 w-12"
                />
                <div className="flex flex-col gap-1 w-full">
                  {selectedFile ? (
                    <Text
                      as="p"
                      className="!text-blue_gray-900_04 !font-poppins"
                    >
                      {selectedFileName}
                    </Text>
                  ) : (
                    <Text
                      as="p"
                      className="!text-blue_gray-900_04 !font-poppins"
                    >
                      Select a file or drag and drop here
                    </Text>
                  )}
                  <Text
                    size="s"
                    as="p"
                    className="!text-blue_gray-300_01 !font-poppins"
                  >
                    .pdf, .jpg, .png
                  </Text>
                </div>
              </div>
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                accept=".pdf,.jpg,.png"
                onChange={(e) => handleUploadFile(e.target.files[0])}
              />
              {errors.selectedFile && (
                <Text as="p" className="text-red-500">
                  {errors.selectedFile}
                </Text>
              )}
            </div>

            {/* Submit Button */}
            <Button
              size="2xl"
              type="submit"
              className="w-full font-medium border-indigo-900 border border-solid !shadow-sm"
            >
              Submit
            </Button>
          </form>
        </div>
      </div>

      {/* Modal Sukses */}
      <DefaultModal
        isOpen={isOpen}
        title="Thank You"
        desc="We have received your fund manager details"
        onButtonClick={() => navigate("/fund-manager-dashboard")}
      />
    </div>
  );
}
