import React, { useState } from "react";
import { Text, Img } from "./..";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logout } from "constant/localStorage";
import { userRoleData } from "constant/userRole";

export default function Header({ ...props }) {
  const navigate = useNavigate();
  const location = useLocation();
  const kycStatus = localStorage.getItem("kycStatus");
  const userRole = localStorage.getItem("userRole");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUpload, setIsOpenUpload] = useState(false);
  const [isMenuOpen, setMenuIsOpen] = useState(false);

  const checkRoleFm = () => {
    return userRole && userRole === userRoleData.FUND_MANAGER ? true : false;
  };

  const profileOptionsOriginator = [
    { href: "/originator", label: "Profile" },
    { href: "/change-password", label: "Change Password" },
    { href: "/", label: "Sign out" },
  ];

  const profileOptionsFundManager = [
    { href: "/fund-manager", label: "Profile" },
    { href: "/change-password", label: "Change Password" },
    { href: "/", label: "Sign out" },
  ];

  const profileOptions = checkRoleFm()
    ? profileOptionsFundManager
    : profileOptionsOriginator;

  const uploadFileOptionsOriginator = [
    { href: "/assets", label: "Assets" },
    { href: "/request-redeem", label: "Request Redeem" },
  ];

  const uploadFileOptionsFundManager = [
    { href: "/assets", label: "Assets" },
    { href: "/request-redeem", label: "FM Redeem" },
  ];

  const uploadFileOptions =
    userRole === userRoleData.FUND_MANAGER
      ? uploadFileOptionsFundManager
      : uploadFileOptionsOriginator;

  const menuOptionsOriginator = [
    { href: "/assets", label: "Assets" },
    { href: "/request-redeem", label: "Request Redeem" },
    { href: "/dashboard", label: "Dashboard" },
    { href: "/originator", label: "Originator" },
    { href: "/", label: "Sign out" },
  ];

  const menuOptionsFundManager = [
    { href: "/assets", label: "Assets" },
    { href: "/request-redeem", label: "Request Redeem" },
    { href: "/fund-manager-dashboard", label: "Dashboard" },
    { href: "/fund-manager", label: "Fund Manager" },
    { href: "/", label: "Sign out" },
  ];

  const menuOptions =
    userRole === userRoleData.FUND_MANAGER
      ? menuOptionsFundManager
      : menuOptionsOriginator;

  const handleMenuToggle = () => {
    setMenuIsOpen(!isMenuOpen);
  };

  const handleItemClick = (link) => {
    if (link.label === "Sign out") {
      logout();
      navigate("/");
      return;
    } else if (link.label === "Profile" && kycStatus === "pending") {
      if (checkRoleFm()) {
        navigate("/fund-manager-form");
        return;
      }
      navigate("/originator-form");
      return;
    } else {
      navigate(link.href);
      return;
    }
  };

  const pathNameDashboard = () => {
    return checkRoleFm() ? "/fund-manager-dashboard" : "/dashboard";
  };

  const pathNameForm = () => {
    return checkRoleFm() ? "/fund-manager-form" : "/originator-form";
  };

  return (
    <header {...props}>
      <div className="flex flex-row justify-center w-full p-1.5 bg-white shadow-xs fixed">
        <div className="flex flex-row justify-between items-center w-full px-5 lg:mr-2 lg:max-w-[1241px]">
          <Link to="/">
            <Img
              src="images/logo_crop.png"
              alt="logo"
              className="w-[130px] md:w-[150px]"
            />
          </Link>
          <div className="lg:hidden">
            <button onClick={handleMenuToggle}>
              <Img
                src="images/img_menu.svg"
                alt="menu"
                className="h-[24px] w-[24px]"
              />
            </button>
          </div>
          <div
            className={`hidden lg:flex flex-row justify-between items-center ${
              kycStatus === "pending" ? "w-[40%]" : "w-[30%]"
            }`}
          >
            {userRole && userRole === userRoleData.ORIGINATOR && (
              <div className="relative">
                {kycStatus === "approved" ? (
                  <Text
                    size="4xl"
                    as="p"
                    onClick={() => {
                      setIsOpenUpload(!isOpenUpload);
                      setIsOpen(false);
                    }}
                    className={
                      location.pathname === "/assets" ||
                      location.pathname === "/request-redeem"
                        ? "!text-indigo-900 !font-semibold underline"
                        : "!text-black-900"
                    }
                  >
                    Upload File
                  </Text>
                ) : (
                  <Text
                    size="4xl"
                    as="p"
                    className="text-gray-500 cursor-not-allowed"
                  >
                    Upload File
                  </Text>
                )}
                {isOpenUpload && (
                  <div className="absolute right-0 mt-5 w-48 bg-white border border-gray-300 rounded-md shadow-lg">
                    {uploadFileOptions.map((link) => (
                      <Text
                        key={link.href}
                        size="xl"
                        as="p"
                        onClick={() => handleItemClick(link)}
                        className="block px-4 py-2 text-gray-800 hover:bg-blue_gray-600 hover:rounded-md !font-semibold hover:text-white"
                      >
                        {link.label}
                      </Text>
                    ))}
                  </div>
                )}
              </div>
            )}

            {kycStatus === "approved" ? (
              <Link to={pathNameDashboard()}>
                <Text
                  size="4xl"
                  as="p"
                  className={
                    location.pathname === pathNameDashboard()
                      ? "!text-indigo-900 !font-semibold underline"
                      : "!text-black-900"
                  }
                >
                  Dashboard
                </Text>
              </Link>
            ) : (
              <Text
                size="4xl"
                as="p"
                className="text-gray-500 cursor-not-allowed"
              >
                Dashboard
              </Text>
            )}

            {checkRoleFm() && kycStatus === "approved" && (
              <Link to="/fund-manager-pool-list">
                <Text
                  size="4xl"
                  as="p"
                  className={
                    location.pathname === "/fund-manager-pool-list"
                      ? "!text-indigo-900 !font-semibold underline"
                      : "!text-black-900"
                  }
                >
                  Pool
                </Text>
              </Link>
            )}

            {kycStatus === "pending" && (
              <Link to={pathNameForm()}>
                <Text
                  size="4xl"
                  as="p"
                  className={
                    location.pathname === pathNameForm()
                      ? "!text-indigo-900 !font-semibold underline"
                      : "!text-black-900"
                  }
                >
                  {checkRoleFm() ? "Fund Manager" : "Originator"}
                </Text>
              </Link>
            )}
            <div className="relative">
              <Img
                onClick={() => {
                  setIsOpenUpload(false);
                  setIsOpen(!isOpen);
                }}
                src="images/img_person.svg"
                alt="avatar"
                className="h-[50px] w-[50px]"
              />
              {isOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg">
                  {profileOptions.map((link) => (
                    <Text
                      key={link.href}
                      size="xl"
                      as="p"
                      onClick={() => handleItemClick(link)}
                      className="block px-4 py-2 text-gray-800 hover:bg-blue_gray-600 hover:rounded-md !font-semibold hover:text-white"
                    >
                      {link.label}
                    </Text>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="fixed right-5 mt-2 top-14 w-40 bg-white border border-gray-300 rounded-md shadow-lg">
          {menuOptions.map((link) => (
            <Text
              key={link.href}
              size="xl"
              as="p"
              onClick={() => handleItemClick(link)}
              className="block px-4 py-2 text-gray-800 hover:bg-blue_gray-600 hover:rounded-md !font-semibold hover:text-white"
            >
              {link.label}
            </Text>
          ))}
        </div>
      )}
    </header>
  );
}
