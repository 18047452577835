import React, { useState, useEffect } from "react";
import { Button, Text, Heading } from "../../../components";
import Header from "../../../components/Header";
import { useLocation } from "react-router-dom";
import { detailOriginator } from "services/originator";
import { useNavigate } from "react-router";

export default function FundManagerReview() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [data, setData] = useState(null);
  const id = queryParams.get("id");

  const fetchData = async () => {
    try {
      const response = await detailOriginator(id);
      setData(response.data.registration_data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  function openPDFInNewTab(url) {
    const fileId = url.split("id=")[1];
    const newUrl = `https://drive.google.com/file/d/${fileId}/view`;

    window.open(newUrl, "_blank");
  }

  return (
    <div className="flex flex-col items-center justify-center w-full pb-10 lg:pb-20 gap-[54px] bg-gray-50">
      <Header className="w-full" />
      {data ? (
        <div className="flex flex-col items-start justify-start w-full max-w-[1241px] pt-10 lg:pt-20 px-5">
          <div className="flex justify-between w-full">
            <div className="flex flex-col">
              <Heading
                size="5xl"
                as="h1"
                className={`ml-px ${
                  data.kyc_status === "rejected"
                    ? "!text-red-A400_01"
                    : data.kyc_status === "approved"
                    ? "!text-teal-700"
                    : "!text-yellow-800"
                }`}
              >
                {data.kyc_status === "rejected"
                  ? "Sorry, your organization account is rejected do to:"
                  : data.kyc_status === "approved"
                  ? "Congratulations, your account is approved."
                  : "Your account is under review"}
              </Heading>
              {data.kyc_status === "rejected" && (
                <Text as="p" className="!text-blue_gray-700 !font-normal mt-2">
                  {data.kyc_rejection_reason}
                </Text>
              )}
            </div>
            {data.kyc_status === "rejected" && (
              <Button
                size="lg"
                onClick={() => navigate("/originator-form")}
                className="tracking-[0.40px] font-poppins uppercase font-medium w-fit"
              >
                Resubmit KYC
              </Button>
            )}
          </div>
          <div className="flex flex-row justify-start w-full p-5 lg:p-[42px] mt-10 border-blue_gray-100_03 border border-solid bg-white rounded-[10px]">
            <div className="grid grid-cols-1 lg:grid-cols-3 justify-between p-5 gap-8 w-full">
              <div className="flex flex-col items-start justify-start w-full">
                <Heading size="5xl" as="h2">
                  Organization Detail
                </Heading>
                <div className="flex flex-col gap-5 mt-8">
                  <div className="flex flex-col items-start justify-start gap-2">
                    <Text size="xl" as="p" className="!text-blue_gray-700">
                      Company Name:
                    </Text>
                    <Heading as="h5">{data.company}</Heading>
                  </div>
                  <div className="flex flex-col items-start justify-start gap-2">
                    <Text size="xl" as="p" className="!text-blue_gray-700">
                      Address:
                    </Text>
                    <Heading as="h6">{data.address}</Heading>
                  </div>
                  <div className="flex flex-col items-start justify-start gap-2">
                    <Text size="xl" as="p" className="!text-blue_gray-700">
                      Registration SKM:
                    </Text>
                    <Heading as="h6">{data.registrasi_skm || ""}</Heading>
                  </div>
                  <div className="flex flex-col items-start justify-start gap-2">
                    <Text size="xl" as="p" className="!text-blue_gray-700">
                      Phone Number:
                    </Text>
                    <Heading as="h6">{data.company_phone || ""}</Heading>
                  </div>
                  <div className="flex flex-col justify-start items-start gap-2">
                    <Text size="xl" as="p" className="!text-blue_gray-700">
                      License:
                    </Text>
                    <Button
                      size="lg"
                      onClick={() => openPDFInNewTab(data.file_license)}
                      className="tracking-[0.40px] font-poppins uppercase font-medium w-fit"
                    >
                      view
                    </Button>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <Heading size="5xl" as="h2">
                  Bank Account
                </Heading>
                <div className="flex flex-col gap-5 mt-8">
                  <div className="flex flex-col items-start justify-start gap-2">
                    <Text size="xl" as="p" className="!text-blue_gray-700">
                      Bank Name:
                    </Text>
                    <Heading as="h5">{data.bank_name}</Heading>
                  </div>
                  <div className="flex flex-col items-start justify-start gap-2">
                    <Text size="xl" as="p" className="!text-blue_gray-700">
                      Account Name
                    </Text>
                    <Heading as="h6">{data.bank_account_name}</Heading>
                  </div>
                  <div className="flex flex-col items-start justify-start gap-2">
                    <Text size="xl" as="p" className="!text-blue_gray-700">
                      Account Number
                    </Text>
                    <Heading as="h6">{data.bank_account_number}</Heading>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start w-full">
                <Heading size="5xl" as="h2">
                  Representative Account
                </Heading>
                <div className="flex flex-col gap-5 mt-8">
                  <div className="flex flex-col items-start justify-start gap-2">
                    <Text size="xl" as="p" className="!text-blue_gray-700">
                      Name:
                    </Text>
                    <Heading as="h5">{data.fullname || ""}</Heading>
                  </div>
                  <div className="flex flex-col items-start justify-start gap-2">
                    <Text size="xl" as="p" className="!text-blue_gray-700">
                      Email:
                    </Text>
                    <Heading as="h6">{data.email || ""}</Heading>
                  </div>
                  <div className="flex flex-col items-start justify-start gap-2">
                    <Text size="xl" as="p" className="!text-blue_gray-700">
                      Phone Number:
                    </Text>
                    <Heading as="h6">{data.phone || ""}</Heading>
                  </div>
                  <div className="flex flex-col items-start justify-start gap-2">
                    <Text size="xl" as="p" className="!text-blue_gray-700">
                      User Role:
                    </Text>
                    <Heading as="h6">{data.user_role?.name || ""}</Heading>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full h-[90vh] justify-center items-center">
          Loading...
        </div>
      )}
    </div>
  );
}
