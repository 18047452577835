import React, { useState, useMemo } from "react";
import { Button, SelectBox, Input, Heading, Img } from "../..";
import { useNavigate } from "react-router-dom";
import { CloseSVG } from "../../../assets/images";
import * as loader from "../../../components/Loading/loading";
import DefaultModal from "components/DefaultModal";

const statusOptions = [
  { label: "All", value: "All" },
  { label: "New", value: "new" },
  { label: "Pending Subscribe", value: "pending_subscribe" },
  { label: "Subscribed", value: "subscribed" },
  { label: "Pending Active", value: "pending_active" },
  { label: "Active", value: "active" },
];

const TableRow = ({ pool }) => {
  const navigate = useNavigate();

  const [isSubscribeOpen, setIsSubscribeOpen] = useState(false);
  const [isActivateOpen, setIsActivateOpen] = useState(false);

  const formatStatus = (text) => {
    if (!text) return "";
    return text
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "new":
        return "text-blue-600";
      case "pending_subscribe":
        return "text-yellow-500";
      case "subscribed":
        return "text-purple-600";
      case "pending_activate":
        return "text-orange-500";
      case "active":
        return "text-green-600";
      default:
        return "text-gray-600";
    }
  };

  const handleToDetail = async () => {
    try {
      navigate(`/fund-manager-pool-detail`, { state: { pool } });
    } catch (error) {
      console.error("Error fetching batch data:", error);
    }
  };

  const handleSubmitSubscribe = () => {
    setIsSubscribeOpen(true);
  };

  const handleCloseSubscribeModal = () => {
    setIsSubscribeOpen(false);
    navigate(`/fund-manager-pool-list`);
  };

  const handleSubmitActivate = () => {
    setIsActivateOpen(true);
  };

  const handleCloseActivateModal = () => {
    setIsActivateOpen(false);
    navigate(`/fund-manager-pool-list`);
  };

  return (
    <>
      <tr className="border-b hover:bg-gray-50">
        <td className="p-3">{pool.id}</td>
        <td className="p-3">{pool.batchId}</td>
        <td className="p-3">{pool.createdDate}</td>
        <td className={`p-3 font-semibold ${getStatusColor(pool.status)}`}>
          {formatStatus(pool.status)}
        </td>
        <td className="p-3 flex gap-2">
          <Button
            className="bg-blue-500 text-white px-1 py-1 rounded"
            onClick={handleToDetail}
          >
            View
          </Button>
          {pool.status === "new" && (
            <Button
              className="bg-yellow-500 text-white px-1 py-1 rounded"
              onClick={handleSubmitSubscribe}
            >
              Subscribe
            </Button>
          )}
          {pool.status === "subscribed" && (
            <Button
              className="bg-green-600 text-white text-sm px-1 py-1 rounded"
              onClick={handleSubmitActivate}
            >
              Request Activate
            </Button>
          )}
        </td>
      </tr>
      <DefaultModal
        isOpen={isSubscribeOpen}
        title="Subscription Request Submitted"
        desc="Your subscription request has been successfully submitted. We will review your request and notify you once it has been processed."
        onButtonClick={handleCloseSubscribeModal}
      />
      <DefaultModal
        isOpen={isActivateOpen}
        title="Activation Request Submitted"
        desc="Your activation request has been successfully submitted. We will review your request and notify you once it has been processed."
        onButtonClick={handleCloseActivateModal}
      />
    </>
  );
};

const Table = ({ data }) => {
  const [statusFilter, setStatusFilter] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handleSearch = (e) => {
    setSearchTerm(e);
    setCurrentPage(1);
  };

  const filteredData = useMemo(() => {
    return data.filter(
      (pool) =>
        (statusFilter === "All" || pool.status === statusFilter) &&
        (pool.batchId.toLowerCase().includes(searchTerm.toLowerCase()) ||
          pool.id.includes(searchTerm))
    );
  }, [data, statusFilter, searchTerm]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <div className="flex flex-col items-center justify-start w-full bg-white shadow-2xl rounded-[30px] p-5 md:p-12 gap-12">
      <Heading size="6xl" as="h2" className="tracking-[-0.22px]">
        Pool List
      </Heading>

      {/* 🔹 Filters */}
      <div className="flex flex-row justify-start gap-5">
        <Input
          color="gray_50_02"
          size="xs"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
          prefix={
            <Img
              src="images/img_search_1.svg"
              alt="search"
              className="cursor-pointer w-5 h-5"
            />
          }
          suffix={
            searchTerm?.length > 0 ? (
              <CloseSVG
                onClick={() => setSearchTerm("")}
                className="cursor-pointer w-5 h-5"
                height={27}
                width={31}
                fillColor="#7e7e7eff"
              />
            ) : null
          }
          className="gap-2.5 text-blue_gray-200 tracking-[-0.12px] !rounded-[10px]"
        />
        <SelectBox
          name="statusfilter"
          color="gray_50_02"
          size="sm"
          options={statusOptions}
          indicator={
            <Img
              src="images/img_arrowdown.svg"
              alt="arrow_down"
              className="absolute w-5"
            />
          }
          onChange={(selected) => setStatusFilter(selected.value)}
          className="w-[160px] gap-px tracking-[-0.12px] font-poppins px-10"
        />
      </div>

      {/* 🔹 Table */}
      <div className="overflow-x-auto w-full">
        <table className="w-full border-collapse bg-white shadow-md rounded-lg">
          <thead className="bg-gray-100 border-b">
            <tr className="text-left">
              <th className="p-3">ID</th>
              <th className="p-3">Batch ID</th>
              <th className="p-3">Created Date</th>
              <th className="p-3">Status</th>
              <th className="p-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length > 0 ? (
              currentItems.map((pool) => <TableRow key={pool.id} pool={pool} />)
            ) : (
              <tr>
                <td colSpan="5" className="text-center p-5">
                  No Data Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* 🔹 Pagination */}
      <div className="flex flex-row justify-between items-center w-full">
        <p className="text-blue_gray-200 md:text-base text-xs tracking-[-0.14px]">
          Showing {indexOfFirstItem + 1} to{" "}
          {Math.min(indexOfLastItem, filteredData.length)} of{" "}
          {filteredData.length} entries
        </p>
        <div className="flex gap-3">
          <Button
            className="px-2"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            {"<"}
          </Button>
          {Array.from({ length: totalPages }, (_, i) => (
            <Button
              key={i}
              className={`px-3 ${
                currentPage === i + 1 ? "bg-blue-500 text-white" : "bg-gray-200"
              }`}
              onClick={() => setCurrentPage(i + 1)}
            >
              {i + 1}
            </Button>
          ))}
          <Button
            className="px-2"
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            {">"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Table;
