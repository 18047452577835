import React, { useState } from "react";
import { Table } from "../../../components/FundManager/Dashboard";
import Header from "../../../components/Header";

const generateDummyData = () => {
  return [
    {
      id: "001",
      batchId: "B-202402",
      createdDate: "2024-02-10",
      status: "new",
    },
    {
      id: "002",
      batchId: "B-202402",
      createdDate: "2024-02-12",
      status: "pending_subscribe",
    },
    {
      id: "003",
      batchId: "B-202402",
      createdDate: "2024-02-15",
      status: "subscribed",
    },
    {
      id: "004",
      batchId: "B-202403",
      createdDate: "2024-02-18",
      status: "pending_active",
    },
    {
      id: "005",
      batchId: "B-202403",
      createdDate: "2024-02-20",
      status: "active",
    },
  ];
};

export default function PoolList() {
  const [data, setData] = useState(generateDummyData());

  return (
    <div className="flex flex-col items-center justify-start w-full pb-[84px] gap-[34px] bg-gray-50">
      <Header className="w-full z-10" />
      {data ? (
        <div className="flex flex-col items-center justify-center md:justify-start w-full gap-[31px] max-w-[1241px] mt-14 md:mt-20 px-5">
          <Table data={data} />
        </div>
      ) : (
        <div className="flex w-full h-[90vh] justify-center items-center">
          Loading...
        </div>
      )}
    </div>
  );
}
