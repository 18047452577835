import { token } from "constant/localStorage";

const API_URL = process.env.REACT_APP_API_URL;

export async function batchData() {
  try {
    const response = await fetch(
      `${API_URL}/batchings?limit=1000&page=1&filter=`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      if (
        errorData.code === 400 &&
        errorData.code_message ===
          "Error get claim from authorization tokenClaim"
      ) {
        // window.location.href = "/login";
      } else {
        throw errorData.code_message;
      }
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function detailBatch(id) {
  try {
    const response = await fetch(`${API_URL}/batchings/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token()}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
        window.location.href = "/login";
      } else {
        throw errorData.code_message;
      }
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}
