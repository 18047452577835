import React from "react";
import { useRoutes } from "react-router-dom";
import NotFound from "pages/NotFound";
import Register from "pages/Register";
import HomePage from "pages/HomePage";
import OriginatorForm from "pages/OriginatorForm";
import OriginatorReview from "pages/OriginatorReview";
import Login from "pages/Login";
import Dashboard from "pages/Dashboard";
import TermsConditions from "pages/TermConditions";
import GuidePage from "pages/Guide";
import RequestRedeem from "pages/RequestRedeem";
import Assets from "pages/Assets";
import ChangePassword from "pages/ChangePassword";
import FundManagerForm from "pages/FundManager/FundManagerForm";
import PoolDashboard from "pages/FundManager/PoolDashboard";
import PoolList from "pages/FundManager/PoolList";
import PoolDetail from "pages/FundManager/PoolDetail";
import FundManagerReview from "pages/FundManager/FundManagerReview";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "*", element: <NotFound /> },
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "register",
      element: <Register />,
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "originator-form",
      element: <OriginatorForm />,
    },
    {
      path: "originator",
      element: <OriginatorReview />,
    },
    {
      path: "dashboard",
      element: <Dashboard />,
    },
    {
      path: "assets",
      element: <Assets />,
    },
    {
      path: "terms-conditions",
      element: <TermsConditions />,
    },
    {
      path: "guide",
      element: <GuidePage />,
    },
    {
      path: "request-redeem",
      element: <RequestRedeem />,
    },
    {
      path: "change-password",
      element: <ChangePassword />,
    },
    {
      path: "fund-manager-form",
      element: <FundManagerForm />,
    },
    {
      path: "fund-manager-dashboard",
      element: <PoolDashboard />,
    },
    {
      path: "fund-manager-pool-list",
      element: <PoolList />,
    },
    {
      path: "fund-manager-pool-detail",
      element: <PoolDetail />,
    },
    {
      path: "fund-manager",
      element: <FundManagerReview />,
    },
  ]);

  return element;
};

export default ProjectRoutes;
