import React, { useState, useEffect } from "react";
import { Button, Input, Heading, Text } from "../../../components";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import DefaultModal from "components/DefaultModal";

// Dummy data (poolData)
const poolData = [
  {
    id: "001",
    batchId: "B-202402",
    worthValue: "$100,000",
    createdDate: "2024-02-10",
    activeStartDate: "2024-02-15",
    activeEndDate: "2024-03-10",
    status: "new",
    logs: ["Created on 2024-02-10"],
  },
  {
    id: "002",
    batchId: "B-202402",
    worthValue: "$150,000",
    createdDate: "2024-02-12",
    activeStartDate: "2024-02-18",
    activeEndDate: "2024-03-15",
    status: "pending_subscribe",
    logs: ["Created on 2024-02-12"],
  },
  {
    id: "003",
    batchId: "B-202402",
    worthValue: "$120,000",
    createdDate: "2024-02-15",
    activeStartDate: "2024-02-20",
    activeEndDate: "2024-03-20",
    status: "subscribed",
    logs: ["Created on 2024-02-15", "Subscribed on 2024-02-20"],
  },
  {
    id: "004",
    batchId: "B-202403",
    worthValue: "$200,000",
    createdDate: "2024-02-18",
    activeStartDate: "2024-02-25",
    activeEndDate: "2024-03-25",
    status: "pending_active",
    logs: ["Created on 2024-02-18", "Activated on 2024-02-25"],
  },
  {
    id: "005",
    batchId: "B-202403",
    worthValue: "$180,000",
    createdDate: "2024-02-20",
    activeStartDate: "2024-02-25",
    activeEndDate: "2024-03-25",
    status: "active",
    logs: ["Created on 2024-02-20"],
  },
];

export default function PoolDetail() {
  const location = useLocation();
  const navigate = useNavigate();

  const [pool, setPool] = useState(null);
  const [newEndDate, setNewEndDate] = useState("");
  const [isUpdateMaturityOpen, setIsUpdateMaturityOpen] = useState(false);

  // Ambil data dari state, misal: location.state?.pool
  const stateData = location.state?.pool;

  // 1. Panggil useEffect di top-level, tanpa conditional
  useEffect(() => {
    if (!stateData) return; // Jika data tidak ada, jangan cari pool

    const selectedPool = poolData.find((p) => p.id === stateData.id);
    if (selectedPool) {
      setPool(selectedPool);
    }
  }, [stateData]);

  // 2. Jika data di state masih kosong, tampilkan pesan
  // (Tetap di dalam body utama, TIDAK membungkus useEffect)
  if (!stateData) {
    return (
      <div className="flex w-full h-[90vh] justify-center items-center">
        Pool data not found!
      </div>
    );
  }

  const handleOpenUpdateMaturityModal = () => {
    setIsUpdateMaturityOpen(true);
  };

  const handleCloseUpdateMaturityModal = () => {
    setIsUpdateMaturityOpen(false);
  };

  const handleUpdateMaturity = () => {
    // alert(`Updated Maturity Date to: ${newEndDate}`);
    handleOpenUpdateMaturityModal();
    setPool({
      ...pool,
      activeEndDate: newEndDate,
    });
  };

  const formatStatus = (text) => {
    if (!text) return "";
    return text
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "new":
        return "text-blue-600";
      case "pending_subscribe":
        return "text-yellow-500";
      case "subscribed":
        return "text-purple-600";
      case "pending_activate":
        return "text-orange-500";
      case "active":
        return "text-green-600";
      default:
        return "text-gray-600";
    }
  };

  // 3. Tampilkan komponen utama
  return (
    <div className="flex flex-col items-center justify-center w-full pb-10 lg:pb-20 gap-[54px] bg-gray-50">
      {/* Header */}
      <Header className="w-full" />

      {/* Pastikan data pool sudah ditemukan */}
      {pool ? (
        <div className="flex flex-col items-start justify-start w-full max-w-[1241px] pt-10 lg:pt-20 px-5">
          {/* Bagian Atas: Judul & Tombol Opsional */}
          <div className="flex justify-between w-full">
            <div className="flex flex-col">
              <Heading
                size="5xl"
                as="h1"
                className="ml-px !text-blue_gray-900_02"
              >
                Pool Detail
              </Heading>
              {/* Informasi tambahan atau keterangan singkat jika diperlukan */}
              <Text as="p" className="!text-blue_gray-700 !font-normal mt-2">
                Here you can review information about the selected pool.
              </Text>
            </div>
            <Button
              size="lg"
              onClick={() => navigate(-1)} // Contoh: kembali ke halaman sebelumnya
              className="tracking-[0.40px] font-poppins uppercase font-medium w-fit"
            >
              Back
            </Button>
          </div>

          {/* Kartu Detail Pool */}
          <div className="flex flex-row justify-start w-full p-5 lg:p-[42px] mt-10 border-blue_gray-100_03 border border-solid bg-white rounded-[10px]">
            <div className="grid grid-cols-1 lg:grid-cols-2 justify-between p-5 gap-8 w-full">
              {/* Kolom Kiri */}
              <div className="flex flex-col gap-5">
                <div className="flex flex-col items-start justify-start gap-2">
                  <Text size="xl" as="p" className="!text-blue_gray-700">
                    Pool ID:
                  </Text>
                  <Heading as="h6">{pool.id}</Heading>
                </div>
                <div className="flex flex-col items-start justify-start gap-2">
                  <Text size="xl" as="p" className="!text-blue_gray-700">
                    Batch ID:
                  </Text>
                  <Heading as="h6">{pool.batchId}</Heading>
                </div>
                <div className="flex flex-col items-start justify-start gap-2">
                  <Text size="xl" as="p" className="!text-blue_gray-700">
                    Worth Value:
                  </Text>
                  <Heading as="h6">{pool.worthValue}</Heading>
                </div>
                <div className="flex flex-col items-start justify-start gap-2">
                  <Text size="xl" as="p" className="!text-blue_gray-700">
                    Created Date:
                  </Text>
                  <Heading as="h6">{pool.createdDate}</Heading>
                </div>
              </div>

              {/* Kolom Kanan */}
              <div className="flex flex-col gap-5">
                <div className="flex flex-col items-start justify-start gap-2">
                  <Text size="xl" as="p" className="!text-blue_gray-700">
                    Active Start Date:
                  </Text>
                  <Heading as="h6">
                    {pool.activeStartDate ? pool.activeStartDate : "-"}
                  </Heading>
                </div>
                <div className="flex flex-col items-start justify-start gap-2">
                  <Text size="xl" as="p" className="!text-blue_gray-700">
                    Active End Date:
                  </Text>
                  <Heading as="h6">
                    {pool.activeEndDate ? pool.activeEndDate : "-"}
                  </Heading>
                </div>
                <div className="flex flex-col items-start justify-start gap-2">
                  <Text size="xl" as="p" className="!text-blue_gray-700">
                    Status:
                  </Text>
                  <Heading className={`${getStatusColor(pool.status)}`} as="h6">
                    {formatStatus(pool.status)}
                  </Heading>
                </div>
              </div>
            </div>
          </div>

          {/* Form Update Maturity */}
          {pool?.status === "active" && (
            <div className="flex flex-col w-full p-5 lg:p-[42px] mt-10 border-blue_gray-100_03 border border-solid bg-white rounded-[10px]">
              <Heading size="5xl" as="h2">
                Update Maturity
              </Heading>
              <div className="flex flex-col md:flex-row items-start md:items-end gap-5 mt-8">
                <div className="flex flex-col gap-2">
                  <Text size="xl" as="p" className="!text-blue_gray-700">
                    New Active End Date:
                  </Text>
                  <Input
                    type="date"
                    value={newEndDate}
                    onChange={(val) => setNewEndDate(val)}
                    className="border !text-black-900 px-2 py-1"
                  />
                </div>
                <Button
                  size="lg"
                  onClick={handleUpdateMaturity}
                  className="tracking-[0.40px] font-poppins uppercase font-medium w-fit bg-blue-500 text-white"
                >
                  Update
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex w-full h-[90vh] justify-center items-center">
          Loading...
        </div>
      )}

      <DefaultModal
        isOpen={isUpdateMaturityOpen}
        title="Update Maturity Date"
        desc="You can update the active end date for this pool. Please make sure the new date aligns with your financial plan."
        onButtonClick={handleCloseUpdateMaturityModal}
      />
    </div>
  );
}
